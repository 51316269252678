import React, { useState, useEffect, useReducer } from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { RunLog } from './components/RunLog';
import { InteractionType } from "@azure/msal-browser";
import { Button, Container, Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { useMsal } from "@azure/msal-react";


function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const { accounts } = useMsal();

    const [runLogIdsList, setRunLogIds] = useState(() => {
        var rawLogIds = localStorage.getItem("runLogIds")
        return !!rawLogIds ? rawLogIds.split(',') : []
    });

    const [minColumnWidth, setMinColumnWidth] = useState(() => {
        return localStorage.getItem("storedColWidth") || 425;
    });

    const outingsSettingsOptions = [
        { value: 'Driver', label: 'Driver Notes' },
        { value: 'Change', label: 'Change Notes' },
        { value: 'Tire', label: 'Tire Info' },
        { value: 'Lap', label: 'Lap Info' },
        { value: 'Weather', label: 'Weather Info' },
        { value: 'Changes', label: 'Setup Changes' }
    ]
    const [outingsListSettings, setOutingsListSettings] = useState([outingsSettingsOptions[0].value]);

    useEffect(() => {
        const debouncedForceReload = debounce(() => {
            forceUpdate()
        }, 100)

        window.addEventListener('resize', debouncedForceReload)
    }, [])

    function debounce(fn, ms) {
        let timer;
        return _ => {
            clearTimeout(timer);
            timer = setTimeout(_ => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        };
    }

    const defaultSelections = () => {
        var storedSelections = localStorage.getItem("storedSelections");

        if (storedSelections) {
            var splitSelections = storedSelections.split(',')
            setOutingsListSettings(splitSelections)
            return outingsSettingsOptions.filter(x => splitSelections.includes(x.value))
        } else {
            return outingsSettingsOptions[0];
        }
    }

    function AddRunLog() {
        var newId = uuid();

        //Set the local storage of the new Id to the same values as the old one to copy over any settings
        if (runLogIdsList && runLogIdsList.length - 1 > -1) {
            var prevRunLogId = runLogIdsList[runLogIdsList.length - 1]
            if (prevRunLogId) {
                localStorage.setItem(newId, localStorage.getItem(prevRunLogId))
            }

        }
        var newIds = [...runLogIdsList, newId];

        localStorage.setItem("runLogIds", newIds)
        setRunLogIds(newIds);
    }

    const DeleteRunLog = (id) => {
        var newIds = runLogIdsList.filter(x => x !== id);
        localStorage.setItem("runLogIds", newIds);
        localStorage.removeItem(id);
        setRunLogIds(newIds);
    }

    const SettingsCheckChange = event => {
        if (event !== undefined) {
            const selectedEvents = event.map(event => event.value)
            localStorage.setItem("storedSelections", selectedEvents)
            setOutingsListSettings(selectedEvents)
        }
    }

    function RunLogsList() {
        var cols = []
        var rows = []

        var actualScreenWidth = document.body.clientWidth * window.devicePixelRatio;
        var numColsPerRow = Math.floor(actualScreenWidth / minColumnWidth); //We want each column to have at least 425 pixels of width
        if (numColsPerRow === 0) numColsPerRow = 1; //But have at least 1

        for (let i = 0; i < runLogIdsList.length; i++) {
            var runLogId = runLogIdsList[i];
            cols.push(
                <Col className='runLog' key={runLogId}>
                    <RunLog id={runLogId} deleteFunction={DeleteRunLog} outingsListSettings={outingsListSettings} savedSelections={localStorage.getItem(runLogId)} />
                </Col>)
            if ((i + 1) % numColsPerRow === 0) {
                rows.push(<Row>{cols}</Row>)
                cols = []
            }
        }

        //Add last uneven row if necessary
        if (cols.length > 0) {
            rows.push(<Row>{cols}</Row>)
        }

        return rows;
    }

    const isTrackhouse = () => {
        if (accounts[0]) {
            if (accounts[0].username.includes("trackhouse")) {
                return true;
            }
        }

        return false;
    }


    const MinColumnWidthTextChange = event => {
        if (event.target.value !== undefined) {
            setMinColumnWidth(event.target.value)
            localStorage.setItem("storedColWidth", event.target.value)
        }
    }


    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent} >
            {isTrackhouse() && <Container fluid>
                <Row style={{ display: 'flex', marginBottom:'8px', marginTop:'-24px' }}>
                    <Col xs={2}>
                        <Button onClick={AddRunLog}>Add Run Log</Button>
                    </Col>
                    <Col xs={2} style={{ alignItems: 'center' }}>
                        <span style={{ fontSize: 10 }}>Min. Column Width: </span>
                        <input style={{ fontSize: 12, width: 30 }} value={minColumnWidth} type="text" onChange={MinColumnWidthTextChange} size="sm" placeholder='Min. Width'></input>
                    </Col>
                    <Col xs={7}>
                        <Select styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'slategray',
                                borderColor: 'slategray'                            
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'slategray',
                                color: 'black'
                                
                            })
                        }} isMulti defaultValue={defaultSelections} closeMenuOnSelect={false} onChange={SettingsCheckChange} options={outingsSettingsOptions} />
                    </Col>
                </Row>
                {RunLogsList()}
            </Container>}
        </MsalAuthenticationTemplate>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}