import { React, useState, useEffect, useRef } from "react";
import "../styles/App.css";
import RVGApi from "../rvgApi";
import ReactToPrint from "react-to-print";
import { Accordion, Form, Card, Stack, Button, CardGroup, useAccordionButton, ListGroup } from "react-bootstrap";
import { IoSettingsSharp } from "react-icons/io5";
import { IoMdPrint } from "react-icons/io";
import { v4 as uuid } from 'uuid';

/**
 * Renders information about a given outing
 * @param props 
 */
export const RunLog = ({ id, deleteFunction, outingsListSettings, savedSelections }) => {
    const [selectedDB, setSelectedDB] = useState("");
    const [DBsList, setDBsList] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState("");
    const [EventsList, setEventsList] = useState(null);
    const [selectedTeamVehicle, setSelectedTeamVehicle] = useState("");
    const [TeamVehiclesList, setTeamVehiclesList] = useState(null);
    const [selectedSession, setSelectedSession] = useState("");
    const [SessionsList, setSessionsList] = useState(null);
    const [OutingsList, setOutingsList] = useState(null);
    const [liveUpdate, setLiveUpdate] = useState(false);
    const [raceOnly, setRaceOnly] = useState(true);
    const [reverseOutingOrder, setReverseOutingOrder] = useState(false);
    const [eventFilterText, setEventFilterText] = useState("");
    const printRef = useRef();
    const [updateInterval, setUpdateInterval] = useState("");
    const [errorCount, setErrorCount] = useState(0);
    const [errorHappening, setErrorHappening] = useState(false)
    const [previousEvent, setPreviousEvent] = useState(null)
    const [previousSession, setPreviousSession] = useState(null)
    const [previousTeamVehicle, setPreviousTeamVehicle] = useState(null)

    const { getApiGroups, getApiEvents, navigationFilterEvent, navigationFilterAll,
        navigationFilterEventTeam, getApiOutings, getApiOutingLaps, getApiOuting,
        getApiOutingAttributes, getApiOutingTire, ApiOutingAttributeGroup } = RVGApi()

    const DBs = DBsList && DBsList.map((DB) =>
        <option key={DB.id}>
            {DB.name}
        </option>);

    const Events = EventsList && EventsList.map((Event) =>
        <option key={Event.eventId}>
            {Event.name}
        </option>);

    const TeamVehicles = TeamVehiclesList && TeamVehiclesList.map((teamVehicle) =>
        <option key={teamVehicle.teamVehicle}>
            {teamVehicle.teamVehicle}
        </option>);

    const Sessions = SessionsList && SessionsList.map((Session) =>
        <option key={Session.sessionId}>
            {Session.name}
        </option>);

    const Outings = () => {

        if (!OutingsList) { return; }
        var outings = reverseOutingOrder ? OutingsList.toReversed() : OutingsList;
        var elements = []

        outings.forEach(outing => {
            elements.push(<OutingInfo key={uuid()} outing={outing} />
            )
        });

        return elements;
    }

    function OutingInfo({ children, outing }) {
        if (!outingsListSettings) {
            return undefined;
        }

        var items = [];
        var notesItems = [];
        var lapInfo = null;
        var otherItems = [];

        if (outingsListSettings.includes("Change") && !!outing.changeNotes) { //Double negative as '' or undefined equates to false
            notesItems.push(
                <Card key={uuid()}>
                    <Card.Header style={{ fontSize: "small" }}>Change Notes</Card.Header>
                    <Card.Text className="runLogOutingNotes">{ClearHTMLTags(outing.changeNotes)}</Card.Text>
                </Card>
            )
        }

        if (outingsListSettings.includes("Driver") && !!outing.driverNotes) {
            notesItems.push(
                <Card key={uuid()}>
                    <Card.Header style={{ fontSize: "small" }}>Driver Notes</Card.Header>
                    <Card.Text className="runLogOutingNotes">{ClearHTMLTags(outing.driverNotes)}</Card.Text>
                </Card>
            )
        }

        if (outingsListSettings.includes("Lap")) {
            var validLapTimes = outing.lapAttributes.filter(x => x.lapTime > 0 && x.trackConditionName === 'Green' && (x.lapTypeName === '' || x.lapTypeName === 'Traffic')).map(x => x.lapTime)
            if (validLapTimes.length > 1) {
                var fastLap = Math.min(...validLapTimes).toFixed(3)
                var averageLap = (validLapTimes.reduce((a, b) => a + b) / validLapTimes.length).toFixed(3)
                var numLaps = outing.lapAttributes.length
                var yellowLaps = outing.lapAttributes.filter(x => x.trackConditionName === 'Yellow').length
                var greenLaps = outing.lapAttributes.filter(x => x.trackConditionName === 'Green').length
                lapInfo = (
                    <Card key={uuid()}>
                        <Card.Text style={{ fontSize: "small" }}>Lap Info: {numLaps} total | GF: {greenLaps} | C: {yellowLaps} | Fast: {fastLap} | Avg: {averageLap} </Card.Text>
                    </Card>)
            }
        }

        if (lapInfo) {
            items.push(lapInfo)
        }

        if (notesItems.length > 0) {
            items.push(<CardGroup style={{ margin: '1px' }} key={"Notes"}>{notesItems}</CardGroup>)
        }

        if (outingsListSettings.includes("Weather")) {
            var trackTemp = outing.outingAttributes.find(x => x.attributeName === 'TrackTemp').value
            var airTemp = outing.outingAttributes.find(x => x.attributeName === 'AirTemp').value
            var windSpeed = outing.outingAttributes.find(x => x.attributeName === 'WindSpeed').value
            var WindDirection = outing.outingAttributes.find(x => x.attributeName === 'WindDirection').value

            var weatherCondition = outing.outingAttributes.find(x => x.attributeName === 'WeatherConditions').value
            var trackCondition = outing.outingAttributes.find(x => x.attributeName === 'TrackConditions').value
            var barometer = outing.outingAttributes.find(x => x.attributeName === 'Barometer').value
            

            var cardTextFirstLine = ``

            if (trackTemp) {
                cardTextFirstLine += ` Track Temp: ${trackTemp} |`
            }

            if (airTemp) {
                cardTextFirstLine += ` Air Temp: ${airTemp} |`
            }

            if (windSpeed && WindDirection) {
                cardTextFirstLine += ` Wind Speed - Direction: ${windSpeed} - ${WindDirection} `
            } else if (windSpeed) {
                cardTextFirstLine += `Wind Speed: ${windSpeed} `
            } else if (WindDirection) {
                cardTextFirstLine += ` Wind Direction: ${WindDirection} `
            }

            var cardTextSecondLine = ``

            if (weatherCondition) {
                cardTextSecondLine += ` Weather: ${weatherCondition} |`
            }

            if (trackCondition) {
                cardTextSecondLine += ` Track: ${trackCondition}`
            }

            items.push(
                <Card key={'Weather'} style={{ padding: '2px', display: 'flex' }}>
                    <Card.Header style={{ fontSize: "small" }}>Weather</Card.Header>
                    <Card.Text style={{ alignSelf: "center", fontSize: "small" }}>{cardTextFirstLine}</Card.Text>
                    <Card.Text style={{ alignSelf: "center", fontSize: "small", marginTop: '-14px' }}>{cardTextSecondLine}</Card.Text>
                </Card>
            )
        }
        

        const findAtt = (name) => {
            var outingAtt = outing.outingAttributes.find(x => x.attributeName === name)
            if (outingAtt) return outingAtt.value
            else return undefined
        }

        // Note this is getting the "outing tire" which contains some new (IE tire set) 
        // and some redundant information (IE wears) in relation to what is in the outingAttributes 

        var staggerItems = [];
        var staggerF = findAtt("StaggerF");
        var staggerR = findAtt("StaggerR")

        if (staggerF || staggerR) {
            if (staggerF) {
                staggerItems.push(<span style={{ fontSize: 'smaller' }}>F: {staggerF}</span>)
            }
            staggerItems.push(<span style={{ fontSize: 'smaller' }}>Stagger</span>)
            if (staggerR) {
                staggerItems.push(<span style={{ fontSize: 'smaller' }}>R: {staggerR}</span>)
            }
        }

        if (outingsListSettings.includes("Tire")) {
            var tireCorners = (
                <Card>
                    <Card.Header style={{ fontSize: "small" }}>Tire Info</Card.Header>
                    <Card className="tireInfoQuads">
                        <div style={{ display: "flex", flexDirection: "column", margin: '0px' }}>
                            {staggerItems}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "small" }}>
                            <TirePressureQuad label={"Cold:"}
                                lf={findAtt("TirePressureColdLF")} rf={findAtt("TirePressureColdRF")}
                                lr={findAtt("TirePressureColdLR")} rr={findAtt("TirePressureColdRR")} />
                            <TirePressureQuad label={"Hot:"}
                                lf={findAtt("TirePressureInLF")} rf={findAtt("TirePressureInRF")}
                                lr={findAtt("TirePressureInLR")} rr={findAtt("TirePressureInRR")} />
                            <TirePressureQuad label={"Build:"}
                                lf={findAtt("TirePressureGainLF")} rf={findAtt("TirePressureGainRF")}
                                lr={findAtt("TirePressureGainLR")} rr={findAtt("TirePressureGainRR")} />
                        </div>
                    </Card>
                    <CardGroup >
                        <TireInfoCorner corner={"LF"} findAtt={findAtt} />
                        <TireInfoCorner corner={"RF"} findAtt={findAtt} />
                    </CardGroup>
                    <CardGroup >
                        <TireInfoCorner corner={"LR"} findAtt={findAtt} />
                        <TireInfoCorner corner={"RR"} findAtt={findAtt} />
                    </CardGroup>
                </Card>
            )
            items.push(tireCorners)
        }

        if (outingsListSettings.includes("Changes") && !!outing.outingAttributes.attributeChanges && outing.outingAttributes.attributeChanges.length > 0) {
            if (outing.outingAttributes.attributeChanges.length > 4) {
                var firstHalfAmount = Math.floor(outing.outingAttributes.attributeChanges.length / 2)
                var firstHalf = outing.outingAttributes.attributeChanges.slice(0, firstHalfAmount)
                var secondHalf = outing.outingAttributes.attributeChanges.slice(firstHalfAmount)
                var firstHalfChanges = firstHalf.map((change) =>
                    <ListGroup.Item style={{ fontSize: "small" }}>{change.attributeName}: {change.valueChange}</ListGroup.Item>)

                var secondHalfChanges = secondHalf.map((change) =>
                    <ListGroup.Item style={{ fontSize: "small" }}>{change.attributeName}: {change.valueChange}</ListGroup.Item>)

                items.push(
                    <Card style={{ padding: '1px' }}>
                        <Card.Header style={{ fontSize: "small" }}>Setup Changes</Card.Header>
                        <CardGroup>
                            <Card style={{ padding: '1px' }}>
                                <ListGroup>
                                    {firstHalfChanges}
                                </ListGroup>
                            </Card>
                            <Card style={{ padding: '1px' }}>
                                <ListGroup>
                                    {secondHalfChanges}
                                </ListGroup>
                            </Card>
                        </CardGroup>
                    </Card>
                )

            } else {
                var changes = outing.outingAttributes.attributeChanges.map((change) =>
                    <ListGroup.Item style={{ fontSize: "small" }}>{change.attributeName}: {change.valueChange}</ListGroup.Item>)

                items.push(
                    <Card style={{ padding: '1px' }}>
                        <Card.Header style={{ fontSize: "small" }}>Setup Changes</Card.Header>
                        <ListGroup>
                            {changes}
                        </ListGroup>

                    </Card>
                )

            }

        }

        if (otherItems.length > 0) {
            items.push(<CardGroup key={"Other"}>{otherItems}</CardGroup>)
        }

        var outingRangeInfo = ''
        //If there are valid lap attributes
        if (outing.lapAttributes && outing.lapAttributes[0] && outing.lapAttributes[outing.lapAttributes.length - 1]) {
            outingRangeInfo = `${outing.lapAttributes[0].sessionLapNumber}-${outing.lapAttributes[outing.lapAttributes.length - 1].sessionLapNumber}`
        }

        return (
            <Card key={outing.outingId}>
                <Card.Header className="runLogOutingHeader">
                    <div className="runLogButtonEventContainer">
                        <span className="runLogOutingNum">{outing.outingNumber}</span>
                        <span className="runLogLapInfo">{outingRangeInfo}</span>
                        <span className="runLogOutingName">{outing.name}</span>
                    </div>
                </Card.Header>
                {items}
            </Card>
        );
    }

    function ClearHTMLTags(inputString){
        inputString = inputString.replace(/<br>/g, 'newline') //Newline placeholder as \n will be replaced below
        inputString = inputString.replace(/<div>/g, 'newline') //Newline placeholder as \n will be replaced below

        inputString = inputString.replace(/<[^>]*/gi, '') //Remove HTML Tags
        inputString = inputString.replace(/�/gi, ' ') //Replace with a space
        inputString = inputString.replace(/&nbsp;/g, ' ')  //replace &nbsp; with a space
        inputString = inputString.replace(/&[^;]+;/g, '') //Remove other HTML entities
        inputString = inputString.replace(/[\u00A0-\u9999<>\&]/gim, '') //Remove unicode
        inputString = inputString.replace(/\t/g, '') //Remove tabs
        inputString = inputString.replace(/\s+/g, ' ').trim(); //Remove multiple spaces

        inputString = inputString.replace(/newline/g, '\n') //replace newline placeholder
        
        inputString = inputString.replace(/[\n]+/, '\n') //replace multiple newlines with one
        
        return inputString
    } 

    const TireInfoCorner = ({ corner, findAtt }) => {
        var leftSide = corner.includes("L");
        var style = leftSide ? "tireCornerLeft" : "tireCornerRight"
        var outingTire = findAtt(`outingTire${corner}`)

        var tireLapsStart = findAtt(`TireLapsStart${corner}`)
        var tireStatus = undefined
        if (tireLapsStart) { //Only fill this out if there is a valid tire laps start att 
            tireStatus = tireLapsStart === '0' ? "Stickers" : "Scuffs"
        }

        var tireSet = ``;
        if (outingTire && outingTire.set) {
            tireSet = `- Set ${outingTire.set}`
        }
        var tireGFLaps = ''
        if (outingTire && outingTire.GFLaps) {
            tireGFLaps = `- ${outingTire.GFLaps} GF Laps`
        }

        var tireInfoItems = []

        if (tireStatus) {
            tireInfoItems.push(<span>{corner} {tireSet} {tireStatus} {tireGFLaps}</span>)
        } else {
            tireInfoItems.push(<span>{corner} {tireSet} {tireGFLaps}</span>)
        }

        var tireTempOut = findAtt(`TireTempOut${corner}`)
        var tireTempMid = findAtt(`TireTempMid${corner}`)
        var tireTempIn = findAtt(`TireTempIn${corner}`)

        var tireWearOut = findAtt(`TireWearOut${corner}D`)
        var tireWearOutMid = findAtt(`TireWearOM${corner}D`)
        var tireWearMid = findAtt(`TireWearMid${corner}D`)
        var tireWearMidIn = findAtt(`TireWearMI${corner}D`)
        var tireWearIn = findAtt(`TireWearIn${corner}D`)

        if (leftSide) {
            //Make sure there's at least one temp
            if (tireTempOut || tireTempMid || tireTempIn) {
                tireInfoItems.push(<span>Temp: {tireTempOut} | {tireTempMid} | {tireTempIn}</span>)
            }

            if (tireWearOut || tireWearOutMid || tireWearMid || tireWearMidIn || tireWearIn) {
                tireInfoItems.push(<span>Wear: {tireWearOut} | {tireWearOutMid} | {tireWearMid} | {tireWearMidIn} | {tireWearIn}</span>)
            }
        } else {
            //Make sure there's at least one temp
            if (tireTempOut || tireTempMid || tireTempIn) {
                tireInfoItems.push(<span>Temp: {tireTempIn} | {tireTempMid} | {tireTempOut}</span>)
            }

            if (tireWearOut || tireWearOutMid || tireWearMid || tireWearMidIn || tireWearIn) {
                tireInfoItems.push(<span>Wear: {tireWearIn} | {tireWearMidIn} | {tireWearMid} | {tireWearOutMid} | {tireWearOut}</span>)
            }
        }

        return (
            <Card className={style}>
                {tireInfoItems}
            </Card>
        )
    }

    const TirePressureQuad = ({ label, lf, rf, lr, rr }) => {
        return (
            <div style={{ display: 'flex', flexDirection: "row", margin: '0px' }}>
                <span style={{ display: 'inline-flex', alignItems: "center", writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}>{label}</span>
                <div className="tireGridContainer">
                    <span style={{ borderBottom: '1px solid grey', borderRight: '1px solid grey', textAlign: 'center', paddingRight: '3px', paddingBottom: '1px' }}>{lf} </span>
                    <span style={{ borderBottom: '1px solid grey', borderLeft: '1px solid grey', textAlign: 'center', paddingLeft: '3px', paddingBottom: '1px' }}>{rf}</span>
                    <span style={{ borderTop: '1px solid grey', borderRight: '1px solid grey', textAlign: 'center', paddingRight: '3px', paddingTop: '1px' }}>{lr}</span>
                    <span style={{ borderTop: '1px solid grey', borderLeft: '1px solid grey', textAlign: 'center', paddingLeft: '3px', paddingTop: '1px' }}>{rr}</span>
                </div>
            </div>
        )
    }


    const SelectedEventId = () => {
        const event = EventsList.find(x => x.name.replace(/ /g, '') === selectedEvent.replace(/ /g, ''));
        if (event) return event.eventId;
        else return event;
    };

    const SelectedVehicleId = () => {
        const teamVehicle = SelectedTeamVehicle()
        if (teamVehicle) return teamVehicle.vehicleId;
        else return teamVehicle;
    };

    const SelectedTeamId = () => {
        const teamVehicle = SelectedTeamVehicle()
        if (teamVehicle) return teamVehicle.teamId;
        else return teamVehicle;
    };

    const SelectedTeam = () => {
        const teamVehicle = SelectedTeamVehicle()
        if (teamVehicle) return teamVehicle.team;
        else return teamVehicle;
    }

    const SelectedTeamVehicle = () => {
        if (TeamVehiclesList && TeamVehiclesList.length > 0) return TeamVehiclesList.find(x => x.teamVehicle.replace(/ /g, '') === selectedTeamVehicle.replace(/ /g, ''));
        else return undefined;
    };


    const SelectedSessionId = () => {
        if (!SessionsList) return undefined;
        const session = SessionsList.find(x => x.name.replace(/ /g, '') === selectedSession.replace(/ /g, ''));
        if (session) return session.sessionId;
        else return session;
    };

    const SelectedDBShort = () => {
        switch (selectedDB) {
            case "Trackhouse":
                return "TRK"
            case "Hendrick":
                return "HMS"
            case "Richard Childress":
                return "RCR"
            case "General Motors":
                return "GM"
            case "Sim Team":
                return "SIM"
            default:
                return selectedDB;
        }
    };

    const TeamBGColor = () => {
        switch (SelectedTeam()) {
            case "1":
                return "#00b0f0"
            case "91":
                return "#c00000"
            case "99":
                return "#3333ff"
            case "5":
                return "#7030a0"
            case "9":
                return "#ff0000"
            case "24":
                return "#ffc000"
            case "48":
                return "#00b050"
            case "3":
                return "#000000"
            case "8":
                return "#a5a5a5"
            case "33":
                return "#ea59f1"
            default:
                return "yellow";
        }
    };

    const TeamFontColor = () => {
        switch (SelectedTeam()) {
            case "5":
            case "99":
            case "3":
            case "8":
                return "white";
            default:
                return "black";
        }
    };

    useEffect(() => {
        async function setDBs() {
            var dbsList = await getApiGroups()

            if (!dbsList) {
                dbsList = await getApiGroups()
                if (!dbsList) return;
            }

            setDBsList(dbsList)
            if (savedSelections) {
                LoadSavedSelections(savedSelections)
            }
        }
        setDBs()
    }, [])

    useEffect(() => {
        if (selectedDB && DBsList) {
            setEventsList(null)
            const updateSelectedDB = setTimeout(() => {
                updateEventsList()                
                setLocalStorage()
            }, 100);
            return () => clearTimeout(updateSelectedDB)
        }
    }, [selectedDB])

    useEffect(() => {
        if (selectedEvent && EventsList) {
            setTeamVehiclesList(null)
            const updateEvent = setTimeout(() => {
                updateTeamsVehiclesList()
                setPreviousTeamVehicle(selectedTeamVehicle)
                setLocalStorage()
            }, 100);
            return () => clearTimeout(updateEvent)
        }
    }, [selectedEvent, EventsList])

    useEffect(() => {
        if (selectedTeamVehicle && TeamVehiclesList) {
            setSessionsList(null)
            const updateTeamVehicle = setTimeout(async () => {
                updateSessionsList() //Update the sessions list as well if applicable
                setPreviousTeamVehicle(selectedTeamVehicle)
                setLocalStorage()
            }, 100)
            return () => clearTimeout(updateTeamVehicle)
        //     if (selectedTeamVehicle !== 'Team - Vehicles') {
        //  }
        }
    }, [selectedTeamVehicle, TeamVehiclesList])

    useEffect(() => {
        if (selectedSession && SessionsList) {
            setOutingsList(null)
            const updateOutings = setTimeout(async () => {
                updateOutingsList()
                setLocalStorage()
            }, 100)

            return () => clearTimeout(updateOutings)
        }
    }, [selectedSession, SessionsList])

    useEffect(() => {
        if (liveUpdate) {
            setUpdateInterval(setInterval(() => {
                updateOutingsList();
            }, 7500));
            setLocalStorage()
        } else {
            clearInterval(updateInterval)
        }
    }, [liveUpdate]);

    useEffect(() => {
        setLocalStorage()
    }, [reverseOutingOrder]);

    useEffect(() => {
        if (OutingsList) {
            updateOutingsList()
        }
    }, [outingsListSettings]);

    useEffect(() => {
        if (errorHappening) {
            setErrorCount(errorCount + 1);
            setErrorHappening(false);
        }

    }, [errorHappening]);

    function setLocalStorage() {
        var storageObj = {}

        if (selectedDB) {
            storageObj.DB = selectedDB;
        }

        if (selectedEvent) {
            storageObj.Event = selectedEvent
        }

        if (selectedTeamVehicle) {
            storageObj.TeamVehicle = selectedTeamVehicle
        }

        if (selectedSession) {
            storageObj.Session = selectedSession
        }

        // if (liveUpdate) {
        //     storageObj.Live = liveUpdate
        // }

        if (reverseOutingOrder) {
            storageObj.reverseOutingOrder = reverseOutingOrder
        }

        if (!!storageObj) {
            localStorage.setItem(id, JSON.stringify(storageObj))
        }
    }

    function LoadSavedSelections(savedSelections) {
        var storageObj = JSON.parse(savedSelections)

        if (Object.keys(storageObj).length < 0) { return };

        if (!!storageObj.DB) {
            setSelectedDB(storageObj.DB)
        }

        if (!!storageObj.Event) {
            setSelectedEvent(storageObj.Event)
        }

        if (!!storageObj.TeamVehicle) {
            setSelectedTeamVehicle(storageObj.TeamVehicle)
        }

        if (!!storageObj.Session) {
            setSelectedSession(storageObj.Session)
        }

        // if (storageObj.Live) {
        //     setLiveUpdate(storageObj.Live)
        // }

        if (storageObj.reverseOutingOrder) {
            setReverseOutingOrder(storageObj.reverseOutingOrder)
        }
    }

    // Race Only Override allows the events list to be updated with the proper  
    // race only setting when Race Events Only is clicks
    const updateEventsList = async (raceOnlyOverride = undefined, filterText = undefined, retry = false) => {
        if (selectedDB) {
            try {

                let onlyRace = raceOnlyOverride !== undefined ? raceOnlyOverride : raceOnly
                var eventsListTemp = await getApiEvents(selectedDB, onlyRace);

                if (filterText) {
                    eventsListTemp = eventsListTemp.filter(event => event.name.toLowerCase().includes(filterText.toLowerCase()))
                }

                setEventsList(eventsListTemp);

                if (previousEvent && eventsListTemp.find(x => x.name === previousEvent)) {
                    setSelectedEvent(previousEvent)
                }

                setTeamVehiclesList(null)
                setSessionsList(null)
                setOutingsList(null)

            } catch (error) {
                //Retry once 
                if (!retry) {
                    updateEventsList(retry = true)
                    console.log("Retrying events list update")
                }
            }
        }
    }

    const updateTeamsVehiclesList = async (retry = false) => {
        const eventId = SelectedEventId();

        try {
            if (eventId) {
                const eventFiltered = await navigationFilterEvent(eventId, selectedDB);
                const teamVehicleCombo = []

                for (let i = 0; i < eventFiltered.filteredTeams.length; i++) {
                    var team = eventFiltered.filteredTeams[i];

                    const eventTeamFiltered = await navigationFilterEventTeam(eventId, team.id, selectedDB)
                    eventTeamFiltered.filteredVehicles.forEach(vehicle => {
                        const teamVehicle = {}
                        teamVehicle.vehicleId = vehicle.vehicleId
                        teamVehicle.vehicle = vehicle.name
                        teamVehicle.teamId = team.id
                        teamVehicle.team = team.name
                        teamVehicle.teamVehicle = `${team.name} - ${vehicle.name}`
                        teamVehicleCombo.push(teamVehicle)
                    })
                }
                setTeamVehiclesList(teamVehicleCombo)

                //If there is an exact match
                if (previousTeamVehicle && teamVehicleCombo.find(x => x.name === previousTeamVehicle)) {
                    setSelectedTeamVehicle(previousTeamVehicle)
                } else if (previousTeamVehicle && teamVehicleCombo) { //If there is a previous selection try and get a match with the same team
                    var splitPrev = previousTeamVehicle.split(' - ')

                    if (splitPrev.length === 2) {
                        var splitTeam = splitPrev[0]
                        var splitVehiclePrimary = splitPrev[1].includes('*')

                        teamVehicleCombo.some(checkMatch);

                        function checkMatch(teamVehicle) {
                            var splitTeamVehicle = teamVehicle.teamVehicle.split(' - ')

                            var splitTeamList = splitTeamVehicle[0]
                            var splitVehiclePrimaryList = splitTeamVehicle[1].includes('*')

                            //TODO: Figure out why this sometimes sets the wrong teamVehicle

                            //This must be a similar vehicle so select it
                            if (splitTeam === splitTeamList && splitVehiclePrimary === splitVehiclePrimaryList) {
                                setSelectedTeamVehicle(teamVehicle.teamVehicle)
                                setPreviousTeamVehicle(teamVehicle.teamVehicle)
                                return true
                            }
                        }
                    }
                }
            }
            setOutingsList(null)
            setSessionsList(null)

        } catch (error) {
            //Retry once 
            if (!retry) {
                updateTeamsVehiclesList(retry = true)
                console.log("Retrying team vehicles list update")
            }
        }
    }

    const updateSessionsList = async (retry = false) => {
        const eventId = SelectedEventId()
        const vehicleId = SelectedVehicleId()
        const teamId = SelectedTeamId()
        try {
            if (eventId && vehicleId && teamId) {
                const allFiltered = await navigationFilterAll(eventId, vehicleId, teamId, selectedDB);
                setSessionsList(allFiltered.filteredSessions)

                if (previousSession && allFiltered.filteredSessions.find(x => x.name === previousSession)) {
                    setSelectedSession(previousSession)
                }
            }

        } catch (error) {
            //Retry once 
            if (!retry) {
                updateSessionsList(retry = true)
                console.log("Retrying sessions list update")
            }
        }
    }

    const updateOutingsList = async (retry = false) => {
        const selectedSessionId = SelectedSessionId()
        if (selectedSessionId) {
            try {
                const shortOutings = await getApiOutings(selectedSessionId, selectedDB)
                const fullOutings = await Promise.all(shortOutings.map(async (short) => await getApiOuting(short.outingId, selectedDB)))
                const outingTires = await Promise.all(shortOutings.map(async (short) => await getApiOutingTire(short.outingId, selectedSessionId, selectedDB)))
                const outingAttributes = await Promise.all(shortOutings.map(async (short) => await getApiOutingAttributes(short.outingId, ApiOutingAttributeGroup.All, selectedDB)))
                const outingLapLists = await Promise.all(shortOutings.map(async (short) => await getApiOutingLaps(short.outingId, selectedDB)))

                const addOutingTireAndGFLaps = (corner) => {
                    for (let i = 0; i < fullOutings.length; i++) {
                        var GFLaps = outingLapLists[i].filter(x => x.trackConditionName === 'Green').length
                        var tire = outingTires[i].find(x => x.tireId === outingAttributes[i].find(x => x.attributeName === `Tire${corner}`).value)

                        if (!tire) continue

                        //Check for earlier outings where the tires were used and gained GF laps
                        for (let j = 0; j < i; j++) {
                            var prevOutingTire = outingAttributes[j].find(x => x.attributeName === `outingTire${corner}`);

                            if (prevOutingTire && prevOutingTire.value && prevOutingTire.value.tireId == tire.tireId) {
                                GFLaps += prevOutingTire.value.GFLaps
                            }
                        }

                        tire['GFLaps'] = GFLaps;

                        var attributePlaceholder = {}
                        attributePlaceholder['attributeName'] = `outingTire${corner}`
                        attributePlaceholder['value'] = tire

                        outingAttributes[i].push(attributePlaceholder)
                    }
                }

                addOutingTireAndGFLaps('LF')
                addOutingTireAndGFLaps('RF')
                addOutingTireAndGFLaps('LR')
                addOutingTireAndGFLaps('RR')

                const isNumeric = (value) => {
                    return !isNaN(value) && !isNaN(parseFloat(value))
                }

                var attributeBlacklist = ['TireWear', 'Weather', 'Barometer', 'PitTime', 'FuelCan',
                    'TireLapsCurrent', 'TrackConditions', 'Humidity', 'Solar', 'Air', 'TrackTemp',
                    'TireLapsStart', 'Wind', 'TirePressureGain', 'TirePressureIn', 'TirePressureAdjustment']

                //Check previous outing for differences 
                for (let i = 1; i < outingAttributes.length; i++) {
                    var attributeChanges = []
                    var prevAttributes = outingAttributes[i - 1]
                    var currAttributes = outingAttributes[i]
                    for (let j = 0; j < currAttributes.length; j++) {
                        var currAtt = currAttributes[j]

                        if (attributeBlacklist.some(attribute => currAtt.attributeName.includes(attribute))) continue

                        //Get the matching attribute in the previous outing
                        var matchingAtt = prevAttributes.filter(att => att.attributeName === currAtt.attributeName)[0]
                        if (!!matchingAtt && matchingAtt.value !== currAtt.value) {
                            var bothNumeric = isNumeric(matchingAtt.value) && isNumeric(currAtt.value)
                            if (!bothNumeric) continue

                            var valueChange = (currAtt.value - matchingAtt.value).toFixed(3)

                            attributeChanges.push({ attributeName: matchingAtt.attributeName, valueChange: valueChange })
                        }
                    }
                    if (attributeChanges.length > 0) {
                        currAttributes.attributeChanges = attributeChanges
                    }
                }

                for (let i = 0; i < fullOutings.length; i++) {
                    fullOutings[i]['outingAttributes'] = outingAttributes[i]
                    fullOutings[i]['lapAttributes'] = outingLapLists[i]
                    fullOutings[i]['outingTires'] = outingTires[i]
                }

                setOutingsList(fullOutings);

            } catch (error) {
                //Retry once if not live update
                if (!liveUpdate && !retry) {
                    updateOutingsList(retry = true)
                    console.log("Retrying outings update")
                }

                if (liveUpdate) {
                    setErrorHappening(true)
                }
            }
        }
    }

    const DBChange = DB => {
        if (DB.target.value !== undefined && DB.target.value !== 'Database') {
            setSelectedDB(DB.target.value);
            setPreviousEvent(selectedEvent)
            setSelectedEvent("")
            setEventsList(null)
        }
    }

    const EventChange = event => {
        if (event.target.value !== undefined && event.target.value !== "Events") {
            setSelectedEvent(event.target.value);
            setPreviousSession(selectedSession)
        }
    }

    const TeamVehicleChange = event => {
        if (event.target.value !== undefined) {
            if (event.target.value !== "Team - Vehicles") {
                setSelectedTeamVehicle(event.target.value);
            } else {
                setPreviousTeamVehicle(selectedTeamVehicle)
                setSelectedTeamVehicle('');
            }
        }
        if (event.target.value == "Team - Vehicles" || event.target.value === '') {
            updateTeamsVehiclesList()
        }
    }
    const SessionChange = event => {
        if (event.target.value !== undefined && event.target.value !== "Events") {
            setSelectedSession(event.target.value);
        }
    }
    const LiveCheckChange = event => {
        if (event.target.value !== undefined) {
            setLiveUpdate(event.target.checked);
        }
    }
    const ReverseCheckChange = event => {
        if (event.target.value !== undefined) {
            setReverseOutingOrder(event.target.checked);
        }
    }
    const RaceOnlyCheckChange = event => {
        if (event.target.value !== undefined) {
            setRaceOnly(event.target.checked);
            updateEventsList(event.target.checked);
        }
    }
    const EventFilterTextChange = event => {
        if (event.target.value !== undefined) {
            setEventFilterText(event.target.value)
            updateEventsList(raceOnly, event.target.value)
        }
    }

    function SettingsToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <Button type="button" onClick={decoratedOnClick} className="runLogSettingsButton">
                <IoSettingsSharp size='1.6em' />
                {children}
            </Button>
        );
    }

    return (
        <Stack>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Stack>
                            <Stack direction="horizontal">
                                <Button onClick={() => deleteFunction(id)}>X</Button>
                                <span className="runLogDB">{SelectedDBShort()}</span>
                                <span className="runLogSession">{selectedSession}</span>
                                <span className="runLogTeam" style={{ backgroundColor: TeamBGColor(), color: TeamFontColor() }}>{SelectedTeam()} </span>
                            </Stack>
                            <div class="container" className="runLogButtonEventContainer" >
                                <span className="runLogEvent">{selectedEvent} </span>
                                {/* <span className="errorCount">Error Count: {errorCount}    </span> */}
                                <Form.Check className="runLogCheck" type="checkbox" label="Live" onChange={LiveCheckChange} checked={liveUpdate} />
                                <Form.Check className="runLogCheck" type="checkbox" style={{ width: 110 }} label="Reverse Outings" onChange={ReverseCheckChange} checked={reverseOutingOrder} />
                                <div style={{ marginLeft: "auto", alignItems: "bottom" }}>
                                    <ReactToPrint bodyClass="printArea" content={() => printRef.current}
                                        trigger={() => (
                                            <Button type="button" className="runLogSettingsButton">
                                                <IoMdPrint size='1.6em' />
                                            </Button>
                                        )}
                                    />
                                    <SettingsToggle eventKey="0" />
                                </div>
                            </div>
                        </Stack>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Form.Select className="runLog-button" value={selectedDB} onChange={DBChange} size="sm">
                                <option>Database</option>
                                {DBs}
                            </Form.Select>
                            <div class="container" style={{ display: "flex", flexDirection: "row" }} >
                                <Form.Check className="runLogCheckRaceOnly" type="checkbox" defaultChecked label="Race Only" onChange={RaceOnlyCheckChange} value={raceOnly} />
                                <Form.Control className="runLogFilterEvents" type="text" placeholder="Filter Events" onChange={EventFilterTextChange} size="sm" />
                            </div>
                            <Form.Select className="runLog-button" value={selectedEvent} onChange={EventChange} size="sm">
                                <option>Events</option>
                                {Events}
                            </Form.Select>
                            <Form.Select className="runLog-button" value={selectedTeamVehicle} onChange={TeamVehicleChange} size="sm">
                                <option>Team - Vehicles</option>
                                {TeamVehicles}
                            </Form.Select>
                            <Form.Select className="runLog-button" value={selectedSession} onChange={SessionChange} size="sm">
                                <option>Sessions</option>
                                {Sessions}
                            </Form.Select>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Stack className="runLogOutings" gap={1} >
                <div ref={printRef}>
                    <Outings />
                </div>
            </Stack>
        </Stack>
    );
};