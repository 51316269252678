import { api, loginRequest } from "./apiConfig"
import { useMsal } from '@azure/msal-react';
import { useRef } from "react";

const RVGApi = () => {
    const { instance, accounts } = useMsal();
    const muhResponse = useRef(undefined);

    async function getToken() {
        if (muhResponse.current === undefined || new Date() > muhResponse.current.expiresOn) {
            muhResponse.current = await instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
        }
        return muhResponse.current.accessToken
    }

    const getApiGroups = async (accessToken = undefined) => {
        if (accessToken === undefined) {
            accessToken = await getToken()
        }

        const bearer = `Bearer ${accessToken}`;

        const response = await api.get(`/UserProfile/raceteam/group/`,
            { headers: { Authorization: bearer } })

        return response.data
    }

    async function get(apiPath, teamSelection = 'Trackhouse') {
        var accessToken = await getToken()

        const bearer = `Bearer ${accessToken}`;

        var groups = await getApiGroups(accessToken);

        var team = groups.find((team) => team.name === teamSelection);

        console.log(`Sending get request: ${apiPath}`)
        const response = await api.get(apiPath,
            { headers: { Authorization: bearer, raceteamselection: team.id } })

        return response.data
    }

    async function getPartTypes(teamSelection = 'Trackhouse') {
        return await get(`/part/type/`, teamSelection)
    }

    async function getApiEvents(teamSelection = 'Trackhouse', raceOnly = true) {
        if (!raceOnly) {
            return await get(`/event/event`, teamSelection)
        } else {
            var eventTypes = await get(`/event/event/type`, teamSelection)
            var raceTypeId = eventTypes.find(x => x.name === "Race").eventTypeId;
            var allEvents = await get(`/event/event`, teamSelection)
            return (allEvents.filter(x => x.eventTypeId === raceTypeId));
        }
    }

    async function getApiEvent(eventId, teamSelection = 'Trackhouse') {
        return await get(`/event/${eventId}`, teamSelection)
    }

    async function getApiSessions(teamSelection = 'Trackhouse') {
        return await get(`/session`, teamSelection)
    }

    async function getApiSession(sessionId, teamSelection = 'Trackhouse',) {
        return await get(`/session/${sessionId}`, teamSelection)
    }

    async function getApiSessionsFiltered(eventId, vehicleId, teamId, teamSelection = 'Trackhouse') {
        return await get(`/session/${eventId}/${vehicleId}/${teamId}`, teamSelection)
    }

    async function getApiOutings(sessionId, teamSelection = 'Trackhouse') {
        return await get(`/outing/list/${sessionId}`, teamSelection)
    }

    async function getApiOuting(outingId, teamSelection = 'Trackhouse') {
        return await get(`/outing/${outingId}`, teamSelection)
    }

    async function getApiOutingTire(outingId, sessionId,  teamSelection = 'Trackhouse') {
        return await get(`/OutingTire/${sessionId}/${outingId}`, teamSelection)
    }

    async function getApiVehicles(teamSelection = 'Trackhouse') {
        return await get(`/vehicle/`, teamSelection)
    }

    async function getApiTeams(teamSelection = 'Trackhouse') {
        return await get(`/event/team`, teamSelection)
    }

    async function getApiVehicleSetup(outingId, teamSelection = 'Trackhouse') {
        return await get(`/VehicleAttribute/${outingId}`, teamSelection)
    }

    async function getApiOutingAttributes(outingId, apiOutingAttributeGroup, teamSelection = 'Trackhouse') {
        return await get(`/outingAttribute/${outingId}/${apiOutingAttributeGroup}`, teamSelection)
    }

    async function getApiOutingLaps(outingId, teamSelection = 'Trackhouse') {
        return await get(`/OutingLap/list/${outingId}/`, teamSelection)
    }

    async function navigationFilterEvent(eventId, teamSelection = 'Trackhouse') {
        return await get(`NavigationFilter/filter?eventId=${eventId}&initialFilter=1`, teamSelection);

    }

    async function navigationFilterEventTeam(eventId, teamId, teamSelection = 'Trackhouse') {
        return await get(`NavigationFilter/filter?eventId=${eventId}&teamId=${teamId}&initialFilter=1&secondaryFilter=3`, teamSelection);

    }

    async function navigationFilterEventVehicle(eventId, vehicleId, teamSelection = 'Trackhouse') {
        return await get(`NavigationFilter/filter?eventId=${eventId}&vehicleId=${vehicleId}&initialFilter=1&secondaryFilter=2`, teamSelection);

    }

    async function navigationFilterAll(eventId, vehicleId, teamId, teamSelection = 'Trackhouse') {
        return await get(`NavigationFilter/filter?eventId=${eventId}&vehicleId=${vehicleId}&teamId=${teamId}&initialFilter=1&secondaryFilter=3`, teamSelection);
    }


    const ApiOutingAttributeGroup = {
        All: 0,
        Fuel: 1,
        Weather: 2,
        PitTime: 3,
        Splitter: 4,
        Engine: 5,
        Balance: 6,
        Dampers: 7,
        Tires: 8,
        Brakes: 9,
        RotorTemperatures: 10,
        TireTemperatures: 11,
        CaliperTemperatures: 12,
        TirePressure: 13,
        TireWearLF: 14,
        TireWearLR: 15,
        TireWearRF: 16,
        TireWearRR: 17,
        RubBlockThickness: 18
    }

    return {
        getApiGroups, getApiEvent, getApiEvents, getApiOuting, getApiOutingTire,
        getApiOutingAttributes, getApiOutingLaps, getApiOutings, getApiSession,
        getApiSessions, getApiSessionsFiltered, getApiTeams, getApiVehicleSetup,
        getApiVehicles, navigationFilterAll, navigationFilterEvent,
        navigationFilterEventTeam, navigationFilterEventVehicle, ApiOutingAttributeGroup
    }
}

export default RVGApi;
