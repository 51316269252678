import { LogLevel } from "@azure/msal-browser";
import axios from "axios"

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        //clientId: "702ce71c-ff48-4ca9-aa93-8096f9f9ac1f", //UAT
        clientId: "e213cdab-723e-4e88-9b99-284e14050848",
        authority: "https://login.microsoftonline.com/54e2aef7-7790-4af2-957b-95f462b78dc7",
        //redirectUri: "https://localhost:3000/"
        //redirectUri: "https://white-water-0c9acff0f.3.azurestaticapps.net"
        redirectUri: "https://trk.haus/"

    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["api://rvg-prod-api/.default"] 
}

export const api = axios.create({
    baseURL: "https://rvg-prod.azurefd.net/api/v1",
    timeout: 7000
})

// defining a custom error handler
const errorHandler = (error) => {
    //Errors are still caught when the api is used but this at least makes it so that any errors are printed
    console.error(`${error.toString()}\nCode: ${error.code}  Status:${error.response.status }`)

    return Promise.reject(error)
  }
  
  // registering the custom error handler to the
  // "api" axios instance
  api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
  })

// export const loginRequest = {
//     scopes: ["api://rvg-uat-api/.default"] 
// }

// export const api = axios.create({
//     baseURL: "https://rvg-uat.azurefd.net/api/v1"
// })